<template>
  <div
    class="topper-banner"
    :style="{
      backgroundColor: type === 'carousel' ? data[bannerIndex].bgColor : '#141c2f'
    }"
  >
    <div
      v-for="(item, index) in data"
      v-show="bannerIndex == index"
      :key="index"
      class="position-bg"
      :style="{
        backgroundColor: `${item.bgColor}`
      }"
    ></div>
    <template v-if="type === 'banner' && data.length > 0">
      <div v-if="showNotice" class="absolute left-0 top-75 z-50 h-30 w-screen bg-[#2b4e8a7f] text-white">
        <div style="width: 1400px; margin: 0 auto" class="flex h-30 items-center justify-between">
          <div>
            <i class="iconfont icon-laba mr-20"></i>
            <span>
<!--              <span style="font-weight: bold;">《SteamDT新功能决策调查问卷》公告：</span><span style="color: #ffffffd9;">SteamDT基础功能日益完善，现诚邀您参与<a href="https://www.wjx.cn/vm/P6hoX86.aspx#" target="_blank" style="text-decoration: underline;color: #0097f3;"> 《SteamDT新功能决策调查问卷》</a>。通过汇总您的意见和投票，我们将共同确定功能开发的优先级。感谢您的参与与支持！</span>-->
              <span style="font-weight: bold;">开放平台新增接口公告：</span><span style="color: #ffffffd9;">新增查询三方平台在售求购等接口，详情请点击查看： <a href="https://doc.steamdt.com/" target="_blank" style="text-decoration: underline;color: #0097f3;">SteamDT开放平台API文档</a></span>
            </span>
          </div>
          <div>
            <i class="iconfont icon-guanbi1 cursor-pointer" @click="closeNotice"></i>
          </div>
        </div>
      </div>
      <el-carousel arrow="never" height="330px" :attr="data.length" @change="changeCarousel">
        <el-carousel-item v-for="(item, index) in data" :key="index">
          <div
            class="banner-bg"
            :style="{
              backgroundColor: item.bgColor
            }"
          >
          <a :href="item.href" v-if='item.href' target="_blank"> <img :src="item.imgUrl"  alt="" /></a>
            <img :src="item.imgUrl" v-else  alt="" />
            <!-- <img v-if="item.type === 1" src="~/assets/images/header-bgs/index-header-banner1.jpg" alt="" />
            <img v-if="item.type === 2" src="~/assets/images/header-bgs/index-header-banner2.jpg" alt="" />
            <img v-if="item.type === 3" src="~/assets/images/header-bgs/index-header-banner1.webp" alt="" />
            <img v-if="item.type === 4" src="~/assets/images/header-bgs/index-header-banner4.webp" alt="" />
            <img v-if="item.type === 5" src="~/assets/images/header-bgs/index-header-banner2.webp" alt="" /> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </template>

    <template v-else>
      <div class="position-bg" style="background-color: #000233"></div>
      <div class="banner-bg">
        <img src="~/assets/images/header-bgs/index-header-bg2.jpg" alt="" />
      </div>
    </template>
  </div>
</template>

<script setup>
import bannerConfig from '~/constants/banner-config'
import banner from '~/components/layout/banner.vue'

const route = useRoute()
const router = useRouter()
const showNotice = ref(false)
const versionNumber = ref('V6')
const readedNotice = useLocalStorage('readedNotice')
watch(
  () => router.currentRoute.value.name,
  toPath => {
    console.log('toPath', toPath)
    routeNameFirstPart.value = toPath ? toPath.split('_')[0] : ''
    if (routeNameFirstPart.value !== 'index') {
      showNotice.value = false
    } else {
      if (readedNotice.value !== versionNumber.value) {
        showNotice.value = true
      }
    }
    if (bannerConfig[routeNameFirstPart.value]) {
      data.value = bannerConfig[routeNameFirstPart.value]
    }
  }
)
const routeNameFirstPart = ref(route.name ? route.name.split('_')[0] : '')

const data = ref([])

onMounted(() => {
  if (readedNotice.value !== versionNumber.value && routeNameFirstPart.value === 'index') {
    showNotice.value = true
  }
})

if (bannerConfig[routeNameFirstPart.value]) {
  data.value = bannerConfig[routeNameFirstPart.value]
}
const props = defineProps({
  type: {
    type: String,
    default: 'banner'
  }
})
const bannerIndex = ref(0)

const changeCarousel = e => {
  bannerIndex.value = e
}

const openUrl = param => {
  if(param){
  window.open(param);
  }
}

const closeNotice = () => {
  showNotice.value = false
  readedNotice.value = versionNumber.value
}
</script>

<style lang="scss" scoped>
.topper-banner {
  width: 1400px;
  // background-color: #151b2f;
  // min-width: 1920px;
  z-index: 10;
  margin: 0 auto;
  .position-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 330px;
    z-index: 1;
  }
  :deep(.el-carousel) {
    min-width: 1920px;
    position: relative;
    left: -260px;
    z-index: 2;
    .el-carousel__indicators {
      bottom: 40px;
    }
    .el-carousel__indicator--horizontal {
      padding: 0 5px;

      &.is-active {
        .el-carousel__button {
          background: $primary-color;
        }
      }
    }
    .el-carousel__button {
      width: 40px;
      height: 10px;
      background: #f7f7f8;
      border-radius: 5px;
      opacity: 1;
    }
  }

  .banner-bg {
    height: 330px;

    // background-color: #151b2f;

    img {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }
}
</style>
